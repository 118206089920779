.rostrum-screen {
    background: #d8d8d8;
    height: 100vh;
    overflow: hidden;
    padding-left: 0;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 100;

    .rostrum-screen__inner {
        background: #fff;
        display: flex;
        height: 100%;
        overflow: hidden;
        padding: 0 20px 0 60px;
        position: relative;
    }
    .rostrum-screen__inner--even-padding {
        padding: 0 60px 0 60px;
    }

    .rostrum-screen__head {
        border-bottom: solid 1px #ff0000;
        left: 40px;
        padding-bottom: 12px;
        position: absolute;
        text-align: left;
        top: 20px;
        width: calc(100% - 80px);

        .rostrum-screen__title {
            font-size: 60px;
            font-weight: bold;
            padding: 0 20px;
            cursor: pointer;

            &:has(.rostrum-screen__title__left, .rostrum-screen__title__right) {
                display: flex;
                justify-content: space-between;
            }

            .rostrum-screen__title__left {
                text-align: left;
            }
            .rostrum-screen__title__right {
                text-align: right;
            }
        }
    }
    .rostrum-screen__head--no-border {
        border-bottom: none;
    }

    .rostrum-screen__center {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        padding-top: 20px;
        width: 100%;
    }
    .rostrum-screen__center--no-padding {
        padding-top: 0;
    }

    .rostrum-screen__footer {
        align-items: end;
        bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        left: 0;
        padding: 0 20px;
        position: absolute;
        text-align: right;
        width: calc(100% - 16px);

        img {
            max-width: auto;
            height: 90px;
        }
    }
    .rostrum-screen__footer--full-width {
        width: calc(100% - 60px);
        padding: 0;
    }
}
