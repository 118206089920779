.rs-current-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    height: calc(100vh - 110px);

    .rs-current-price__price {
        margin-bottom: 0.5rem;
    }

    .rs-current-price__buyer {
        font-size: 1rem;
    }
}
