.auctioneer-screen {
    background: #d8d8d8;
    display: grid;
    grid-template-columns: 55% 45%;
    height: 100%;
    padding-left: 80px;
    overflow: hidden;
    position: relative;

    .auctioneer-screen__left {
        background: #fff;
        padding: 16px;
    }

    .auctioneer-screen__right {
        display: grid;
        gap: 16px;
        grid-template-rows: auto auto auto;
        height: calc(100vh);
        padding: 16px 16px 0 16px;
    }


    .auctioneer-screen__no-bid-yet {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        padding: 32px;
    }
}
