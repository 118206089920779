.as-head {
    .Lot-lotNumber {
        font-size: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .text-primary {
            font-size: 40px;
        }

        .mat-mdc-chip-action-label {
            font-size: 30px;
            padding: 10px;
        }

        .mat-mdc-chip.mat-mdc-standard-chip {
            height: 40px;
        }
    }
}

.rostrum-screen {
    .as-head {
        cursor: pointer;

        .rostrum-screen__lot-chip {
            position: absolute;
            top: 10px;
            right: 0;
        }
    }
}
