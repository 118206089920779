.rs-last-finished-lots {
    --mat-table-header-headline-size: 55px;
    --mat-table-cell-size: 40px;
    --mat-table-header-headline-weight: bold;
    --mat-table-cell-weight: 400;

    .rs-last-finished-lots__table {
        height: calc(100vh - 300px);
        overflow-y: auto;
        width: 100%;

        .rs-last-finished-lots__body-cell,
        .rs-last-finished-lots__header-cell {
            border: 2px solid #fff;
            text-align: center;
            padding: 0;
        }

        .rs-last-finished-lots__header-cell {
            font-size: 35px !important;
            border-bottom-width: 2px;
            padding: 20px;

            &.table-header-aveWeight {
                width: 300px;
            }

            &.table-header-pricePerHead,
            &.table-header-pricePerKg {
                width: 350px;
            }
        }
        .rs-last-finished-lots__body-cell {
            font-size: 40px;
            font-weight: bold;

            div {
                display: block;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 20px;
            }

            .rs-last-finished-lots__chip {
                font-size: 30px;
            }
        }

        .rs-last-finished-lots__body-cell--bold {
            font-weight: 800;
        }

        .rs-last-finished-lots__body-cell.lot-number {
            font-weight: bold;
        }

        tbody tr {
            .rs-last-finished-lots__body-cell {
                background: #f1f1f1;
            }

            &:nth-child(odd) {
                .rs-last-finished-lots__body-cell {
                    background: #fff;
                }
            }
        }

        .mat-mdc-table tbody,
        .mat-mdc-table tfoot,
        .mat-mdc-table thead,
        .mat-mdc-cell,
        .mat-mdc-footer-cell,
        .mat-mdc-header-row,
        .mat-mdc-row,
        .mat-mdc-footer-row,
        .mat-mdc-table .mat-mdc-header-cell {
            background: transparent;
        }
    }
}
